// export type Indexes =
//     | '[[-1,-1],[-1,0],[-1,1],[0,-1],[0,1],[1,-1],[1,0],[1,1]]'
//     | '[[-1,-1],[-1,0],[-1,1],[0,-1],[0,1],[1,0],[1,1]]'
//     | '[[-1,0],[-1,1],[0,-1],[0,1],[1,-1],[1,0],[1,1]]'
//     | '[[-1,-1],[-1,0],[-1,1],[0,-1],[0,1],[1,-1],[1,0]]'
//     | '[[-1,-1],[-1,0],[0,-1],[0,1],[1,-1],[1,0],[1,1]]'
//     | '[[-1,-1],[-1,0],[0,-1],[0,1],[1,0],[1,1]]'
//     | '[[-1,0],[-1,1],[0,-1],[0,1],[1,-1],[1,0]]'
//     | '[[-1,0],[-1,1],[0,-1],[0,1],[1,0],[1,1]]'
//     | '[[-1,-1],[-1,0],[0,-1],[0,1],[1,-1],[1,0]]'
//     | '[[-1,-1],[-1,0],[-1,1],[0,-1],[0,1],[1,0]]'
//     | '[[-1,0],[0,-1],[0,1],[1,-1],[1,0],[1,1]]'
//     | '[[-1,0],[0,-1],[0,1],[1,0],[1,1]]'
//     | '[[-1,0],[-1,1],[0,-1],[0,1],[1,0]]'
//     | '[[-1,-1],[-1,0],[0,-1],[0,1],[1,0]]'
//     | '[[-1,0],[0,-1],[0,1],[1,-1],[1,0]]'
//     | '[[-1,0],[0,-1],[0,1],[1,0]]'
//     | '[[-1,0],[-1,1],[0,1],[1,0],[1,1]]'
//     | '[[-1,0],[0,1],[1,0],[1,1]]'
//     | '[[-1,0],[-1,1],[0,1],[1,0]]'
//     | '[[-1,0],[0,1],[1,0]]'
//     | '[[-1,-1],[-1,0],[-1,1],[0,-1],[0,1]]'
//     | '[[-1,-1],[-1,0],[0,-1],[0,1]]'
//     | '[[-1,0],[-1,1],[0,-1],[0,1]]'
//     | '[[-1,0],[0,-1],[0,1]]'
//     | '[[-1,-1],[-1,0],[0,-1],[1,-1],[1,0]]'
//     | '[[-1,0],[0,-1],[1,-1],[1,0]]'
//     | '[[-1,-1],[-1,0],[0,-1],[1,0]]'
//     | '[[-1,0],[0,-1],[1,0]]'
//     | '[[0,-1],[0,1],[1,-1],[1,0],[1,1]]'
//     | '[[0,-1],[0,1],[1,0],[1,1]]'
//     | '[[0,-1],[0,1],[1,-1],[1,0]]'
//     | '[[0,-1],[0,1],[1,0]]'
//     | '[[-1,0],[-1,1],[0,1]]'
//     | '[[-1,0],[0,1]]'
//     | '[[0,1],[1,0],[1,1]]'
//     | '[[0,1],[1,0]]'
//     | '[[-1,-1],[-1,0],[0,-1]]'
//     | '[[-1,0],[0,-1]]'
//     | '[[0,-1],[1,-1],[1,0]]'
//     | '[[0,-1],[1,0]]'
//     | '[[-1,0],[1,0]]'
//     | '[[0,-1],[0,1]]'
//     | '[[-1,0]]'
//     | '[[0,1]]'
//     | '[[1,0]]'
//     | '[[0,-1]]'
//     | '[null]';

export const svgs = {
    '[[-1,-1],[-1,0],[-1,1],[0,-1],[0,1],[1,-1],[1,0],[1,1]]': (
        <polygon points='91.75 0 8.25 0 0 0 0 8.25 0 91.75 0 100 8.25 100 91.75 100 100 100 100 91.75 100 8.25 100 0 91.75 0' />
    ),
    '[[-1,-1],[-1,0],[-1,1],[0,-1],[0,1],[1,0],[1,1]]': (
        <path d='M91.75,0H0V100H100V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[-1,0],[-1,1],[0,-1],[0,1],[1,-1],[1,0],[1,1]]': (
        <path d='M91.75,0H8.25c0,4.56-3.69,8.25-8.25,8.25V100H100V0h-8.25Z' />
    ),
    '[[-1,-1],[-1,0],[-1,1],[0,-1],[0,1],[1,-1],[1,0]]': (
        <path d='M91.75,0H0V100H91.75c0-4.56,3.69-8.25,8.25-8.25V0h-8.25Z' />
    ),
    '[[-1,-1],[-1,0],[0,-1],[0,1],[1,-1],[1,0],[1,1]]': (
        <path d='M91.75,0H0V91.75c4.56,0,8.25,3.69,8.25,8.25H100V0h-8.25Z' />
    ),
    '[[-1,-1],[-1,0],[0,-1],[0,1],[1,0],[1,1]]': (
        <path d='M91.75,0H0V91.75c4.56,0,8.25,3.69,8.25,8.25H100V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[-1,0],[-1,1],[0,-1],[0,1],[1,-1],[1,0]]': (
        <path d='M91.75,0H8.25c0,4.56-3.69,8.25-8.25,8.25V100H91.75c0-4.56,3.69-8.25,8.25-8.25V0h-8.25Z' />
    ),
    '[[-1,0],[-1,1],[0,-1],[0,1],[1,0],[1,1]]': (
        <path d='M91.75,0H8.25c0,4.56-3.69,8.25-8.25,8.25V100H100V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[-1,-1],[-1,0],[0,-1],[0,1],[1,-1],[1,0]]': (
        <path d='M91.75,0H0V91.75c4.56,0,8.25,3.69,8.25,8.25H91.75c0-4.56,3.69-8.25,8.25-8.25V0h-8.25Z' />
    ),
    '[[-1,-1],[-1,0],[-1,1],[0,-1],[0,1],[1,0]]': (
        <path d='M91.75,0H0V100H91.75c0-4.56,3.69-8.25,8.25-8.25V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[-1,0],[0,-1],[0,1],[1,-1],[1,0],[1,1]]': (
        <path d='M91.75,0H8.25c0,4.56-3.69,8.25-8.25,8.25V91.75c4.56,0,8.25,3.69,8.25,8.25H100V0h-8.25Z' />
    ),
    '[[-1,0],[0,-1],[0,1],[1,0],[1,1]]': (
        <path d='M91.75,0H8.25c0,4.56-3.69,8.25-8.25,8.25V91.75c4.56,0,8.25,3.69,8.25,8.25H100V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[-1,0],[-1,1],[0,-1],[0,1],[1,0]]': (
        <path d='M91.75,0H8.25c0,4.56-3.69,8.25-8.25,8.25V100H91.75c0-4.56,3.69-8.25,8.25-8.25V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[-1,-1],[-1,0],[0,-1],[0,1],[1,0]]': (
        <path d='M91.75,0H0V91.75c4.56,0,8.25,3.69,8.25,8.25H91.75c0-4.56,3.69-8.25,8.25-8.25V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[-1,0],[0,-1],[0,1],[1,-1],[1,0]]': (
        <path d='M91.75,0H8.25c0,4.56-3.69,8.25-8.25,8.25V91.75c4.56,0,8.25,3.69,8.25,8.25H91.75c0-4.56,3.69-8.25,8.25-8.25V0h-8.25Z' />
    ),
    '[[-1,0],[0,-1],[0,1],[1,0]]': (
        <path d='M91.75,0H8.25c0,4.56-3.69,8.25-8.25,8.25V91.75c4.56,0,8.25,3.69,8.25,8.25H91.75c0-4.56,3.69-8.25,8.25-8.25V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[-1,0],[-1,1],[0,1],[1,0],[1,1]]': (
        <polygon points='83.5 8.25 50 8.25 16.5 8.25 0 8.25 0 82.41 0 91.75 0 100 8.25 100 91.75 100 100 100 100 91.75 100 82.41 100 8.25 83.5 8.25' />
    ),
    '[[-1,0],[0,1],[1,0],[1,1]]': (
        <path d='M83.5,8.25H0V91.75c4.56,0,8.25,3.69,8.25,8.25H100V8.25h-16.5Z' />
    ),
    '[[-1,0],[-1,1],[0,1],[1,0]]': (
        <path d='M83.5,8.25H0V100H91.75c0-4.56,3.69-8.25,8.25-8.25V8.25h-16.5Z' />
    ),
    '[[-1,0],[0,1],[1,0]]': (
        <path d='M83.5,8.25H0V91.75c4.56,0,8.25,3.69,8.25,8.25H91.75c0-4.56,3.69-8.25,8.25-8.25V8.25h-16.5Z' />
    ),
    '[[-1,-1],[-1,0],[-1,1],[0,-1],[0,1]]': (
        <polygon points='13.07 0 8.25 0 0 0 0 8.25 0 91.75 0 100 8.25 100 13.07 100 91.75 100 91.75 83.5 91.75 50 91.75 16.5 91.75 0 13.07 0' />
    ),
    '[[-1,-1],[-1,0],[0,-1],[0,1]]': (
        <path d='M29.39,0H0V91.75c4.56,0,8.25,3.69,8.25,8.25H91.75V0H29.39Z' />
    ),
    '[[-1,0],[-1,1],[0,-1],[0,1]]': (
        <path d='M8.25,0c0,4.56-3.69,8.25-8.25,8.25V100H91.75V0H8.25Z' />
    ),
    '[[-1,0],[0,-1],[0,1]]': (
        <path d='M8.25,0c0,4.56-3.69,8.25-8.25,8.25V91.75c4.56,0,8.25,3.69,8.25,8.25H91.75V0H8.25Z' />
    ),
    '[[-1,-1],[-1,0],[0,-1],[1,-1],[1,0]]': (
        <polygon points='91.75 0 8.25 0 0 0 0 8.25 0 12.19 0 91.75 16.5 91.75 50 91.75 83.5 91.75 100 91.75 100 12.19 100 8.25 100 0 91.75 0' />
    ),
    '[[-1,0],[0,-1],[1,-1],[1,0]]': (
        <path d='M91.75,0H8.25c0,4.56-3.69,8.25-8.25,8.25V91.75H100V0h-8.25Z' />
    ),
    '[[-1,-1],[-1,0],[0,-1],[1,0]]': (
        <path d='M91.75,0H0V91.75H100V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[-1,0],[0,-1],[1,0]]': (
        <path d='M91.75,0H8.25c0,4.56-3.69,8.25-8.25,8.25V91.75H100V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[0,-1],[0,1],[1,-1],[1,0],[1,1]]': (
        <polygon points='91.75 0 73.37 0 8.25 0 8.25 16.5 8.25 50 8.25 83.5 8.25 100 73.37 100 91.75 100 100 100 100 91.75 100 8.25 100 0 91.75 0' />
    ),
    '[[0,-1],[0,1],[1,0],[1,1]]': (
        <path d='M91.75,0H8.25V100H100V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[0,-1],[0,1],[1,-1],[1,0]]': (
        <path d='M91.75,0H8.25V100H91.75c0-4.56,3.69-8.25,8.25-8.25V0h-8.25Z' />
    ),
    '[[0,-1],[0,1],[1,0]]': (
        <path d='M91.75,0H8.25V100H91.75c0-4.56,3.69-8.25,8.25-8.25V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[-1,0],[-1,1],[0,1]]': (
        <path d='M83.5,8.25H0V100H91.75V16.5c0-4.56-3.69-8.25-8.25-8.25Z' />
    ),
    '[[-1,0],[0,1]]': (
        <path d='M83.5,8.25H0V91.75c4.56,0,8.25,3.69,8.25,8.25H91.75V16.5c0-4.56-3.69-8.25-8.25-8.25Z' />
    ),
    '[[0,1],[1,0],[1,1]]': (
        <path d='M83.5,8.25H16.5c-4.56,0-8.25,3.69-8.25,8.25V100H100V8.25h-16.5Z' />
    ),
    '[[0,1],[1,0]]': (
        <path d='M83.5,8.25H16.5c-4.56,0-8.25,3.69-8.25,8.25V100H91.75c0-4.56,3.69-8.25,8.25-8.25V8.25h-16.5Z' />
    ),
    '[[-1,-1],[-1,0],[0,-1]]': (
        <path d='M8.25,0H0V91.75H83.5c4.56,0,8.25-3.69,8.25-8.25V0H8.25Z' />
    ),
    '[[-1,0],[0,-1]]': (
        <path d='M8.25,0c0,4.56-3.69,8.25-8.25,8.25V91.75H83.5c4.56,0,8.25-3.69,8.25-8.25V0H8.25Z' />
    ),
    '[[0,-1],[1,-1],[1,0]]': (
        <path d='M91.75,0H8.25V83.5c0,4.56,3.69,8.25,8.25,8.25H100V0h-8.25Z' />
    ),
    '[[0,-1],[1,0]]': (
        <path d='M91.75,0H8.25V83.5c0,4.56,3.69,8.25,8.25,8.25H100V8.25c-4.56,0-8.25-3.69-8.25-8.25Z' />
    ),
    '[[-1,0],[1,0]]': (
        <polygon points='83.5 8.25 50 8.25 16.5 8.25 0 8.25 0 91.75 16.5 91.75 50 91.75 83.5 91.75 100 91.75 100 8.25 83.5 8.25' />
    ),
    '[[0,-1],[0,1]]': (
        <polygon points='8.25 0 8.25 16.5 8.25 50 8.25 83.5 8.25 100 91.75 100 91.75 83.5 91.75 50 91.75 16.5 91.75 0 8.25 0' />
    ),
    '[[-1,0]]': (
        <path d='M83.5,8.25H0V91.75H83.5c4.56,0,8.25-3.69,8.25-8.25V16.5c0-4.56-3.69-8.25-8.25-8.25Z' />
    ),
    '[[0,1]]': (
        <path d='M83.5,8.25H16.5c-4.56,0-8.25,3.69-8.25,8.25V100H91.75V16.5c0-4.56-3.69-8.25-8.25-8.25Z' />
    ),
    '[[1,0]]': (
        <path d='M83.5,8.25H16.5c-4.56,0-8.25,3.69-8.25,8.25V83.5c0,4.56,3.69,8.25,8.25,8.25H100V8.25h-16.5Z' />
    ),
    '[[0,-1]]': (
        <path d='M8.25,0V83.5c0,4.56,3.69,8.25,8.25,8.25H83.5c4.56,0,8.25-3.69,8.25-8.25V0H8.25Z' />
    ),
    '[]': (
        <path d='M83.5,8.25H16.5c-4.56,0-8.25,3.69-8.25,8.25V83.5c0,4.56,3.69,8.25,8.25,8.25H83.5c4.56,0,8.25-3.69,8.25-8.25V16.5c0-4.56-3.69-8.25-8.25-8.25Z' />
    ),
};
